<script>
import { mapStores, mapWritableState } from 'pinia'

import SignInApple from '@/components/SignInApple.vue'
import { useAccountStore, useOptionsStore, useAppStore } from '@/stores'
import liffapi from '@/liffapi.js'
import utils from '@/utils.js'

export default {
  name: 'LoginView',

  components: { SignInApple },

  props: {},

  data: () => ({
    isLoading: false,
    isDone: false,
    form: {
      email: null,
      token: null,
      appcheck: null,
    },
    response: null,
  }),

  computed: {
    ...mapWritableState(useOptionsStore, ['opts']),
    ...mapStores(useAppStore, useAccountStore),

    ok() {
      return utils.isValidEmail(this.form.email)
    },
  },

  mounted() {
    if (this.$route.params.token) {
      console.log('token: ', this.$route.params.token)
    }
  },

  methods: {
    async submit(form) {
      console.log(form)
      this.isLoading = true
      let appcheck = await this.app.token
      form.appcheck = appcheck
      this.account.setAuth(form.email, null)
      liffapi.account
        .login(form)
        .then((response) => {
          console.log(response)
          utils.toast(response.message, 'success')
          this.response = response
          this.isDone = true
        })
        .catch((err) => {
          let msg =
            err?.data?.message || 'There was an error. Please try again later.'
          if (err.status == 409) {
            // auth email sent
            this.account.clearAuth()
            this.isDone = true
            return
          }
          utils.toast(msg, 'danger')
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
}
</script>

<template>
  <div v-if="!isDone" class="account-view has-text-centered section">
    <h1 class="title mb-6">Login</h1>

    <template v-if="app.hasSigninWithApple">
      <section>
        <SignInApple> </SignInApple>
      </section>

      <p style="margin: 2rem 0">- or -</p>
    </template>

    <section>
      <o-field class="" style="" label="">
        <o-input
          v-model="form.email"
          required
          type="email"
          list="defaultEmails"
          expanded
          placeholder="Enter your email"></o-input>
        <template #message>
          <p>Where the login email will be sent.</p>
        </template>
      </o-field>

      <o-field class="">
        <o-button
          :disabled="!ok"
          class="is-fat-btn is-liff"
          :class="{ 'is-loading': isLoading }"
          variant="primary"
          size="medium"
          @click="submit(form)">
          Send login link
        </o-button>
      </o-field>
    </section>
  </div>
  <div v-else class="is-blue has-text-centered">
    <h1 class="is-size-1 has-text-white mb-6">
      Check your <span class="is-yellow">email.</span>
    </h1>
    <p class="has-text-white mb-6">
      We just sent you a temporary login link. Please check your inbox at
      {{ response.entity }}.
    </p>
  </div>
</template>

<style lang="scss">
.is-blue {
  background-color: #007bff;
  width: 100vw;
  height: 100vh;
  padding: 2rem;
}
</style>

<style lang="scss" scoped></style>
